import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware((to) => {
  const router = useRouter()
  const { loggedIn } = storeToRefs(useAuthStore())
  const authStore = useAuthStore()
  const { userCan } = authStore

  if (to.meta?.permission && !userCan(to.meta?.permission)) {
    throw createError({
      statusCode: 403,
      statusMessage: 'You are not authorized to access this page.',
    })
  }

  if (!loggedIn.value) {
    return router.push({
      path: '/auth/login',
      query: {
        next: to.path,
      },
    })
  }
})
